import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import './TableStyle.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PaginationBar from './PaginationBar';
import TimeFormatter from '../../../utility/newTimeformatter/TimeFormatter';
import { MdLogout } from "react-icons/md";
import Hexapi from '../../../utility/HexAPI/Hexapi';
import { Modal } from 'react-bootstrap';
import { Textbox } from '../../../utility/HexInput/Textbox';
import Swal from 'sweetalert2';

var oldData = []
export default class Datatable extends Component {
    constructor() {
        super()
        this.state = {
            rowData: [], isDataFound: false, currentPaginationPage: 0, showPaginationRows: 0,
            isShowSearchBox: false, searchValue: '',
            isModalexit:false,
            visitorid:"",
            remarks:""
        }
        this.storeremarks = this.storeremarks.bind(this);
        this.handleremark = this.handleremark.bind(this);
        this.handleexitmodal=this.handleexitmodal.bind(this)
    }
   

    handleexitmodal(id){
        this.setState({isModalexit:true,
            visitorid:id
        })
    }

    storeremarks(e) {
        this.setState({ remarks: e.target.value })
    }

    async visitorexit() {
        try {
            this.setState({ isModalexit: false }); 
            const result = await Swal.fire({
                text: 'Are you sure you want to Exit ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'yes-button',
                    cancelButton: 'no-button'
                }
            });
    
            if (result.isConfirmed) {
                await this.handleremark();  
               
            } else {
                this.setState({ isModalexit: false }); 
            }
        } catch (error) {
            console.error('Error during exit confirmation:', error);
        }
    }
    
    async handleremark(e) {
        try {
            const visitorID = this.state.visitorid;
            const remarks = this.state.remarks;
            const orgDbName = sessionStorage.getItem("orgdbname");
    
            const obj = {
                'query': `[dbo].[VRApp_Web_Proc_ExitForm_Submitv2]@VisitorID='{0}',@ExitRemarks='{1}'`,
                'orgdbname': `${orgDbName}`,
                'queryArr': [`${visitorID}`, `${remarks}`]
            };
    
            const resp = await Hexapi(obj); 
            console.log(resp, "API Response");
            if (resp[""][0]["command"] === 1) {
                this.setState({ ismodalexit: false });
                await Swal.fire({
                    text: resp[""][0]["msg"],
                    confirmButtonColor: '#9987aa'
                });

                this.props.refreshtable()
            }
    
        } catch (error) {
            console.error("API Call Failed:", error);
        }
    }
    
    componentDidMount() {
        let tabledata=this.props.data
            tabledata = tabledata.map((item) => {
                return { ExitForm: '',  ...item }
            })
        this.setState({ rowData: tabledata, isDataFound: true })
        oldData = tabledata
        this.props.pagination && this.setState({ showPaginationRows: this.props.pagination.rowsPerPage })
    }
    getHeader(data) {
        // console.log(data);
        // console.log(Object.keys(data[0]));
        return Object.keys(data[0]).map((heading) => {
            // {
            // if (heading != "recid") {
                if (heading == "ExitForm") {
                    return <th>ExitForm</th>
                }
            if (heading == "visitorid") {
                return <th>Visitor ID</th>
            }
            if (heading == "firstname") {
                return <th>First Name</th>
            }
            if (heading == "lastname") {
                return <th>Last Name</th>
            }
            if (heading == "contactnumber") {
                return (
                    <th>Contact Number</th>
                )
            }
            if (heading == "visitorcount") {
                return (
                    <th>No. Of Visitor</th>
                )
            }
            if (heading == "persontomeet") {
                return (
                    <th>Person to Meet</th>
                )
            }
            if (heading == "purpose") {
                return (
                    <th>Purpose</th>
                )
            }
            if (heading == "visitortype") {
                return (
                    <th>Visitor Type</th>

                )
            }
            if (heading == "visitstatus") {
                return (
                    <th>Visit Status</th>
                )
            }
            if (heading == "intime") {
                return (
                    <th>In Time</th>
                )
            }
            if (heading == "outtime") {
                return (
                    <th></th>
                )
            }
            if (heading == "companyname") {
                return (
                    <th>Company Name</th>
                )
            }
            if (heading == "recid") {
                return (
                    <th></th>
                )
            }

            return <th key={heading}>{heading}</th>
            //    }
            // // console.log(heading)
            // }
        })
    }
    clearSearch(e) {
        this.setState({ rowData: oldData, isDataFound: true, isShowSearchBox: false, searchValue: '' })
    }
    setDate(date) {
        if (date != undefined) {
            // return date.split(' ')[0]
            console.log(date)
            var transformdate = date.split(".")[0];
            console.log(transformdate)
            //   console.log(date);
            var exitdate = TimeFormatter(transformdate);
            console.log(exitdate);
            var visitorinfo = this.state.visitorinfo
            //   console.log(visitorinfo)
            // console.log(this.state.visitorinfo)
            var newReverseDate =
                exitdate.ShiftTime +
                "," +
                exitdate.userSplitedDate[2] +
                "-" +
                exitdate.ShiftMonth +
                "-" +
                exitdate.CurYear.toString().slice(-2);
            console.log(newReverseDate);

            return newReverseDate;
        }
    }
    getRowsData(data) {
        var newTableData = [].concat(data)
        let newData = newTableData
        if (this.props.pagination) {
            newData = newTableData.filter((row, index) => {
                let rowPerPage = this.state.showPaginationRows < 0 ? newTableData.length : this.state.showPaginationRows
                let start = this.state.currentPaginationPage * rowPerPage
                let end = (this.state.currentPaginationPage + 1) * rowPerPage
                if (index >= start && index < end) return true
            })
        }
        return newData.map((row, i) => {
            return (
                <tr key={i} >
                    {
                        Object.keys(row).map((heading) => {
                            if (heading == "ExitForm") {
                                return (
                                    <td onClick={()=>this.handleexitmodal(row.visitorid)}><MdLogout /></td>
                                )
                            }
                            if (heading == "recid") {
                                return (
                                    <td></td>
                                )
                            }
                            if (heading == "outtime") {
                                return (
                                    <td></td>
                                )
                            }
                            if (row[heading] == null) {
                                row[heading] = ''
                            }
                            if (typeof row[heading] != 'object') {
                                // return <td key={row[heading]} dangerouslySetInnerHTML={{ __html: row[heading] }} />
                                return <td onClick={() => this.props.onRowClick(row)} key={row} dangerouslySetInnerHTML={{ __html: row[heading] }} />
                                // return <td >{row[heading]}</td>
                            } else {
                                // return <td >{this.setDate(row[heading].date) }</td>
                                // return <td key={row[heading]} dangerouslySetInnerHTML={{ __html: this.setDate(row[heading].date) }} /> 
                                return <td onClick={() => this.props.onRowClick(row)} key={row} dangerouslySetInnerHTML={{ __html: this.setDate(row[heading].date) }} />
                            }
                        }
                        )
                    }
                </tr>
            )
        })
    }
    storeSearch(e) {
        console.log(oldData)
        let value = e.target.value
        this.setState({ searchValue: value })
        let applyFilter = Object.keys(oldData[0])

        // console.log(value)
        // console.log(applyFilter)
        if (value == '') {
            if (oldData.length > 0) {
                console.log(oldData)
                this.setState({ rowData: oldData, isDataFound: true })
                // console.log(rowData)
            } else {
                this.setState({ isDataFound: false })
            }
        } else {
            if ((oldData.filter((row) => {
                if (Array.isArray(applyFilter)) {
                    let StrArr = []
                    for (let i = 0; i < applyFilter.length; i++) {
                        StrArr.push(row[`${applyFilter[i]}`])
                        // console.log(StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase()))
                        // console.log(StrArr)
                    }
                    return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                } else {
                    return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase())
                }
            })).length > 0) {
                this.setState({
                    rowData: (oldData.filter((row) => {
                        if (Array.isArray(applyFilter)) {
                            let StrArr = []
                            for (let i = 0; i < applyFilter.length; i++) {
                                StrArr.push(row[`${applyFilter[i]}`])
                                // console.log(StrArr)
                            }

                            return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                            // console.log(StrArr)
                        } else {
                            return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase())()
                            // console.log(StrArr)
                        }
                    }))
                    , isDataFound: true
                }, () => {
                    this.setState({ currentPaginationPage: 0 })
                })


            } else {
                this.setState({ isDataFound: false })
            }
        }
    }

    //'rgba(85,37,131,255)'
    render() {

        return (
            <>
                <div style={{ height: 'calc(78vh - 5px)' }}>
                    <div style={{ position: 'relative', height: "inherit" }}>
                        {
                            this.props.isNavbar &&
                            <div className="_tablenavbar" style={{ backgroundColor: 'rgb(153, 135, 170)' }}>
                                <div className="_left" >
                                    <span>{this.props.heading}</span>
                                </div>
                                <div className="_right">
                                    <input type="text" id="searchBarFocus" autoFocus={true} value={this.state.searchValue} style={{ width: this.state.isShowSearchBox ? "250px" : '0px', padding: this.state.isShowSearchBox ? "6px 12px" : "6px 0px", marginLeft: this.state.isShowSearchBox ? "10px" : '0px' }} className="editable" placeholder='Search...' onChange={(e) => this.storeSearch(e)} />
                                    {
                                        this.state.isShowSearchBox ?
                                            <Tooltip title="Close Search">
                                                <IconButton style={{ color: '#fff' }} onClick={() => this.clearSearch()}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip>

                                            :
                                            <Tooltip title="Open Search">
                                                <IconButton style={{ color: '#fff' }} onClick={() => this.setState({ isShowSearchBox: true }, () => {
                                                    if (document.getElementById('searchBarFocus')) {
                                                        document.getElementById('searchBarFocus').focus()
                                                    }
                                                })}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </Tooltip>

                                    }
                                    {/* <Tooltip title={'Filter'}>
                                    <IconButton style={{ color: '#fff' }}>
                                        <FilterAltIcon />
                                    </IconButton>
                                </Tooltip> */}
                                </div>
                            </div>
                        }
                        <Table id='_tableGrid' className={'table-hover'} responsive style={{ width: "140%" }}>
                            <thead style={this.props.headStyle}>
                                <tr>
                                    {this.state.isDataFound &&
                                        this.state.rowData.length > 0 && this.getHeader(this.state.rowData)}
                                </tr>
                            </thead>
                            {

                                this.state.isDataFound &&
                                    this.state.rowData.length > 0 &&
                                    this.state.isDataFound ?
                                    <tbody >
                                        {
                                            this.getRowsData(this.state.rowData)
                                        }
                                    </tbody>
                                    :

                                    <div style={{ marginTop: '10%', marginLeft: '15cm' }}>
                                        <h3>No Data Found!</h3>
                                    </div>


                            }
                        </Table>
                        {
                            this.props.pagination &&
                            this.state.isDataFound &&
                            <div className="_tablefooter" style={{ backgroundColor: 'rgb(153, 135, 170)' }}>
                                <PaginationBar
                                    rowsPerPage={this.props.pagination.rowsPerPage}
                                    count={this.state.rowData.length}
                                    rowsPerPageOptions={this.props.pagination.rowsPerPageOptions}
                                    onPageChange={(currentPage) => this.setState({ currentPaginationPage: currentPage })}
                                    onRowsChange={(rows) => this.setState({ showPaginationRows: rows, currentPaginationPage: 0 })}
                                />
                            </div>
                        }

                    </div>
                </div>

                { this.state.isModalexit && <Modal centered show={this.state.isModalexit} onHide={()=>{this.setState({isModalexit:false})}}>
                    <Modal.Body style={{padding:"0px"}}>
                    <div class="shadow-lg p-3 rounded" >

<div className='row' style={{padding:"10px", fontWeight:"700" }}>
    <center><h3>Exit Form</h3></center>
</div>

<label style={{ color: "black", fontWeight: "800" }}>Remarks</label>
<Textbox
    label={"Remarks"}
    name="broadcast_message"
    id="broadcastmsg"
    placeholder='Enter Remarks'
    required={true}
    value={this.state.remarks}
    onChange={(e) => {
        this.storeremarks(e)
    }}
/>

<div className='submit' style={{ justifyContent: "center", display: "flex", marginTop: "20px" }}>
    <button onClick={() => this.visitorexit()} style={{ color: 'white', flexDirection: "row", justifyContent: "right", backgroundColor: '#9987aa' }} class="btn " type="submit">Submit</button>
</div>

</div>
                    </Modal.Body>
                    </Modal>}
            </>

        )
    }
}
